import { Injectable } from "@angular/core";

/**
 * A configuration service to be used by the AuthGuard service to find 
 * the redirection url after an authentication failure 
 */
@Injectable({
    providedIn: 'root'  
})
export class RedirectionRoutesConfigurationService {

    private readonly _DEFAULT_REDIRECTION_ROUTE = "/welcome"; //todo check changes
    private readonly _SPECIAL_REDIRECTION_ROUTES = [
        {
            key: "/collection\/[a-z0-9]{24}\/[a-zA-Z0-9]{128}",
            replaceable: /collection\/[a-z0-9]{24}\/[a-zA-Z0-9]{128}/,
            replaceWith: "appsumo/collections/register", 
        }
    ];

    constructor() {

    }

    /**
     * Returns the redirection route.
     * If the given url fits one of the special redirection keys then its corresponding route is returned,
     * otherwise the default route is returned
     */
    getRedirectionRoute = (url: string): string => {
        if (url) {
            let redirectionRoute = "";
            for (let index = 0; index < this._SPECIAL_REDIRECTION_ROUTES.length; index++) {
                const currentSpecialRedirectionRoute = this._SPECIAL_REDIRECTION_ROUTES[index]
                const keyRegExp = new RegExp(currentSpecialRedirectionRoute.key);
                if (keyRegExp.test(url)) {
                    redirectionRoute = url.replace(currentSpecialRedirectionRoute.replaceable, currentSpecialRedirectionRoute.replaceWith);
                    break;
                }
            }

            return redirectionRoute || this._DEFAULT_REDIRECTION_ROUTE;
        }
    }
}
